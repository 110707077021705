import {Collapse} from "antd";
import React from "react";
import {bold, boldBlue, boldOrange, getAlgorithmTag, getCategoryTag, getDescriptionTag,} from "../../Utils/HtmlUtils";
import {roundToFixed2} from "../../API/Utils";
import {Item} from "../../Packing/Item";


const {Panel} = Collapse;

type ItemsViewProps = {
    itemsData: any,
    orderValue: number,
    otherSolutions?: any
}


const ItemsView: React.SFC<ItemsViewProps> = (props) => {

    let totalNumItems = 0;
    let totalCargoWeight = 0;
    let totalCargoVolume = 0;

    props.itemsData.forEach((item: any) => {
        totalNumItems += item.quantity;
        totalCargoWeight += item.weight_lbs_item * item.quantity;
        totalCargoVolume += item.volume_cube_ft_item * item.quantity;
    })


    return (
        <div>
            <p> {boldBlue(totalNumItems)} {boldBlue('Items')} </p>
            <p>{bold('Value: ')} {boldOrange(props.orderValue)}</p>
            <p>{bold('Cargo: ')} {roundToFixed2(totalCargoWeight)} lbs, {roundToFixed2(totalCargoVolume)} cb_ft</p>
            <Collapse>
                <Panel header={`Item Details`} key="1" style={{}}>

                    <table>
                        <thead>

                        <tr
                            style={{
                                fontSize: '15px',
                                textAlign: 'center'
                            }}
                        >
                            <th>SKU</th>
                            <th>Pic</th>
                            <th>Item</th>
                        </tr>

                        </thead>


                        <tbody>

                        {props.itemsData.map((itemBodyRaw: any, index: number) => {
                            let itemBody = Item.fromRaw(itemBodyRaw);


                            return (
                                <tr key={index} style={{
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    backgroundColor: index % 2 === 0 ? '#d4ebf2' : 'white'

                                }}>


                                    <td style={{border: '1px solid black'}}>
                                        <p
                                            style={{
                                                fontSize: '12px',
                                                textAlign: 'center',
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            QTY: {itemBodyRaw.quantity}
                                        </p>
                                        <p>
                                            {itemBody.getItemType()}
                                        </p>
                                        <p>
                                            {
                                                itemBody?.getCategoryAsList()
                                                    .map((category: string, index: number) => getCategoryTag(category, 0, index))
                                            }
                                        </p>

                                    </td>

                                    <td style={{border: '1px solid black'}}>
                                        <img
                                            src={itemBody?.getImageThumbnail()}
                                            alt={''}
                                            style={{
                                                width: '75px',
                                                height: '75px',
                                                borderRadius: '50%',
                                                border: '2px solid #1890ff',
                                            }}
                                        />
                                    </td>

                                    <td style={{border: '1px solid black'}}>
                                        <div>
                                            <p>{bold(itemBody?.getBrandName())}: {itemBody?.getProductName()}</p>
                                            <p>{itemBody?.getVariantName()}</p>
                                            <p>
                                                {
                                                    itemBody?.getDescriptionTags()
                                                        .map((tag: string, index: number) => getDescriptionTag(tag, 0, index))
                                                }
                                            </p>
                                        </div>
                                    </td>

                                </tr>
                            )
                        })}

                        </tbody>

                    </table>
                </Panel>
            </Collapse>

            <div
                style={{
                    marginTop: '2%'
                }}
            >
                {props.otherSolutions ? <Collapse>
                    <Panel header={`Other Solutions`} key="1" style={{}}>

                        <table>
                            <thead>

                            <tr
                                style={{
                                    fontSize: '15px',
                                    textAlign: 'center'
                                }}
                            >
                                <th>ID</th>
                                <th>Algo</th>
                                <th>Time</th>
                                <th>Cost</th>


                            </tr>

                            </thead>


                            <tbody>

                            {props.otherSolutions.map((solutionBody: any, index: number) => {


                                return (
                                    <tr key={index} style={{
                                        fontSize: '12px',
                                        textAlign: 'center',
                                        backgroundColor: index % 2 === 0 ? '#d4ebf2' : 'white'

                                    }}>


                                        <td style={{border: '1px solid black'}}>
                                            <div>
                                                <a
                                                    href={`/pack_solution/${solutionBody.solution_id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {solutionBody.solution_id.substring(0, 8)}
                                                </a>
                                            </div>

                                        </td>

                                        <td style={{
                                            border: '1px solid black',
                                        }}>
                                            {getAlgorithmTag(solutionBody.algorithm_name)}
                                        </td>

                                        <td style={{
                                            border: '1px solid black',
                                        }}>
                                            {bold(roundToFixed2(solutionBody.optimization_time_seconds))}
                                        </td>

                                        <td style={{border: '1px solid black'}}>
                                            {boldBlue(' $' + roundToFixed2(solutionBody.theoretical_shipping_cost))}
                                        </td>

                                    </tr>
                                )
                            })}

                            </tbody>

                        </table>
                    </Panel>
                </Collapse> : null}
            </div>

        </div>
    )
}

export default ItemsView
