import {backendPostCall} from "../API/Utils";
import {ItemManager} from "../Packing/ItemManager";


export class ActionLoggingService {
    private actionEndpoint: string = '/log-action';
    private itemManager: ItemManager;
    private actionCache: Array<any> = []
    private cacheRefreshTimeSeconds;

    public constructor(
        itemManager: ItemManager,
        cacheRefreshTimeSeconds: number = 30
    ) {
        this.cacheRefreshTimeSeconds = cacheRefreshTimeSeconds
        this.itemManager = itemManager;

        setInterval(() => {
            this.processCache()
        }, this.cacheRefreshTimeSeconds * 1000)

    }


    public logShipmentCheckout(
        shipmentId: number,
        deposcoShipmentId: string,
        boxId: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this.logAction('SHIPMENT_CHECKOUT', {
            shipment_id: shipmentId,
            deposco_shipment_id: deposcoShipmentId,
            box_id: boxId,
            box_name: this.itemManager.getShippingBox(shipmentId).getBoxName(),
            ...this._getQuantityCountsObject(shipmentId)
        }, callback)
    }

    public logMoveItemAction(
        itemId: string,
        shipmentIdFrom: number,
        shipmentIdTo: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {

        this.logAction('MOVE_ITEM', {
            item_id: itemId,
            shipment_id_from: shipmentIdFrom,
            box_name_from: this.itemManager.getShippingBox(shipmentIdFrom).getBoxName(),
            shipment_id_to: shipmentIdTo,
            box_name_to: this.itemManager.getShippingBox(shipmentIdTo).getBoxName(),
            ...this._getQuantityCountsObject(shipmentIdFrom, itemId)
        }, callback)
    }

    public logResetScanAllAction(
        shipmentId: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this.logAction('RESET_SCAN_ALL', {
            shipmentId: shipmentId,
            box_name: this.itemManager.getShippingBox(shipmentId).getBoxName(),
            ...this._getQuantityCountsObject(shipmentId)
        }, callback)
    }

    public logResetScanForItemInShipmentAction(
        itemId: string,
        shipmentId: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this.logAction('RESET_SCAN_ITEM', {
            item_id: itemId,
            shipment_id: shipmentId,
            box_name: this.itemManager.getShippingBox(shipmentId).getBoxName(),
            ...this._getQuantityCountsObject(shipmentId, itemId)
        }, callback)
    }


    public logScanAllAction(
        shipmentId: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this.logAction('SCAN_ALL', {
            shipmentId: shipmentId,
            box_name: this.itemManager.getShippingBox(shipmentId).getBoxName(),
            ...this._getQuantityCountsObject(shipmentId)
        }, callback)
    }

    public logScanErrorAction(
        upc: string,
        errorMessage: string,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this.logAction('SCAN_ERROR', {
            upc: upc,
            error_message: errorMessage,
            ...this._getQuantityCountsObject()
        }, callback)
    }

    public logOverScanAction(
        itemId: string,
        shipmentId: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        this.logAction('OVER_SCAN', {
            item_id: itemId,
            shipment_id: shipmentId,
            box_name: this.itemManager.getShippingBox(shipmentId).getBoxName(),
            ...this._getQuantityCountsObject(shipmentId, itemId)

        }, callback)

    }

    public logScanItemAction(
        itemId: string,
        shipmentId: number,
        callback: (data: any) => void = (data: any) => {
        }
    ) {

        let itemQuantityObject = this._getQuantityCountsObject(shipmentId, itemId)

        this.logAction(
            'SCAN_ITEM',
            {
                item_id: itemId,
                shipment_id: shipmentId,
                box_name: this.itemManager.getShippingBox(shipmentId).getBoxName(),
                ...itemQuantityObject
            }, callback)
    }


    public logAction(
        actionName: string,
        otherParams: any = {},
        callback: (data: any) => void = (data: any) => {
        }
    ) {
        const params = {
            packer: this.itemManager.getAuthService().getUsername(),
            station_id: this.itemManager.getPackingStationId(),
            order_id: this.itemManager.getOrderId(),
            tote_id: this.itemManager.getToteId(),
            request_id: this.itemManager.getRequestId(),
            solution_id: this.itemManager.getSolutionId(),
            action_name: actionName,
            ...otherParams
        }
        this._addToCache(params, callback)
    }


    private _addToCache(params: any, callback: (data: any) => void = (data: any) => {
    }) {
        this.actionCache.push({
            ...params,
            action_time_utc: new Date().toISOString()
        })
        callback({})

    }

    private _call(events: any, callback: (data: any) => void = (data: any) => {
    }) {
        backendPostCall(
            this.actionEndpoint,
            {
                events: events
            }
        ).then((r: any) => {
            try {
                callback(r);
            } catch (e: any) {
                console.error('Something happened when calling the ACTION LOG endpoint...')
                console.log(e)
                callback({})

                // alert(e)
            }
        })

    }

    private _getQuantityCountsObject(shipmentId: number = -1, itemId: string = 'N/A', prefix: string = ''): any {
        let virtualBox = this.itemManager.getVirtualBox(shipmentId)

        let result = {}

        if (itemId !== 'N/A') {
            let itemQuantityScanned = virtualBox.getNumberOfScannedItemsForType(itemId)
            let itemQuantityRemaining = virtualBox.getNumberOfUnscannedItemsForType(itemId)
            let itemTotalToScan = itemQuantityScanned + itemQuantityRemaining
            result = {
                item_quantity_scanned: itemQuantityScanned,
                item_quantity_remaining: itemQuantityRemaining,
                item_total_to_scan: itemTotalToScan,
            }

        }

        if (shipmentId !== -1) {
            let shipmentQuantityScanned = virtualBox.getNumberOfScannedItems()
            let shipmentQuantityRemaining = virtualBox.getNumberOfUnscannedItems()
            let shipmentTotalToScan = shipmentQuantityScanned + shipmentQuantityRemaining
            result = {
                ...result,
                shipment_quantity_scanned: shipmentQuantityScanned,
                shipment_quantity_remaining: shipmentQuantityRemaining,
                shipment_total_to_scan: shipmentTotalToScan,
            }
        }


        let totalSolutionQuantityScanned = this.itemManager.getTotalQuantityScanned()
        let totalSolutionQuantityRemaining = this.itemManager.getTotalQuantityRemaining()
        let totalSolutionTotalToScan = totalSolutionQuantityScanned + totalSolutionQuantityRemaining

        return {
            ...result,
            total_solution_quantity_scanned: totalSolutionQuantityScanned,
            total_solution_quantity_remaining: totalSolutionQuantityRemaining,
            total_solution_quantity_to_scan: totalSolutionTotalToScan,
            total_number_of_shipments: this.itemManager.getNumberOfShipments(),
        }

    }


    private processCache() {
        let cache = this.actionCache
        this.actionCache = []

        if (cache.length > 0) {
            this._call(cache)
        }
    }


}
