import {Input, Select, Spin} from "antd";
import React, {useState, useEffect, useRef} from "react";
import {bold} from "../../Utils/HtmlUtils";
import {ItemOperationProps} from "./ItemOperationProps";

const {Option} = Select;


const MoveItemInput: React.SFC<ItemOperationProps> = (props) => {
    const [oldNumScans, setOldNumScans] = useState(props.currentNumScans);
    const scanInput = useRef(null);

    const [fromBoxSlot, setFromBoxSlot] = useState('')
    const [fromShipmentId, setFromShipmentId] = useState(0)
    const [toBoxSlot, setToBoxSlot] = useState('')
    const [toShipmentId, setToShipmentId] = useState(0)

    // Initialize the state of the shipments
    // If there are 2 shipments or more, set the fromBoxSlot and toBoxSlot to the first and second shipment
    // Otherwise, set the fromBoxSlot and toBoxSlot to first shipment
    useEffect(() => {
        if (props.itemManager.getNumShipments() >= 2) {
            let shipmentIds = props.itemManager.getShipmentIdList();
            let shipment1Id = shipmentIds[0]
            let shipment2Id = shipmentIds[1]

            let shipment1Slot = props.itemManager.getFullBoxLocation(+shipment1Id)
            let shipment2Slot = props.itemManager.getFullBoxLocation(+shipment2Id)

            setFromShipmentId(shipment1Id)
            setToShipmentId(shipment2Id)

            setFromBoxSlot(shipment1Slot)
            setToBoxSlot(shipment2Slot)

        } else if (props.itemManager.getNumShipments() === 1) {
            let shipmentIds = props.itemManager.getShipmentIdList();
            let shipment1Id = shipmentIds[0]
            let shipment1Slot = props.itemManager.getFullBoxLocation(+shipment1Id)
            setFromShipmentId(shipment1Id)
            setFromBoxSlot(shipment1Slot)
            setToShipmentId(shipment1Id)
            setToBoxSlot(shipment1Slot)
        } else {
            alert('No shipments found')
        }
    }, [props.itemManager])


    useEffect(() => {
        if (props.isActive && props.currentNumScans > 0 && props.currentNumScans !== oldNumScans) {
            props.itemManager.moveItem(
                props.packingStationId,
                props.scannedItemUpc,
                fromShipmentId,
                toShipmentId
            )

            // Little hack to make sure the focus goes back to the input field
            setTimeout(() => {
                if (scanInput.current) {
                    // @ts-ignore
                    scanInput.current.focus({preventScroll: true})
                }
            }, 100)
        }

        setOldNumScans(props.currentNumScans);

    }, [
        props.currentNumScans,
        oldNumScans,
        props.itemManager,
        props.packingStationId,
        props.scannedItemUpc,
        fromShipmentId,
        toShipmentId,
        props.isActive
    ]);


    return (<div>
            {props.loading ? <div>
                    <Spin/>
                </div> :
                <div>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '90%',
                                marginLeft: '5%',
                                marginTop: '2%',
                                marginBottom: '2%'
                            }}
                        >
                            <label>{bold('From Box:')}</label>
                            <Select
                                style={{
                                    width: '50%'
                                }}
                                placeholder="Please select"
                                value={fromBoxSlot}
                                onChange={(value: any) => {
                                    setFromShipmentId(value)
                                    setFromBoxSlot(props.itemManager.getFullBoxLocation(value))
                                }}
                            >
                                {props.itemManager.getShipmentIdList().map((shipmentId: number) => {
                                    let slotNumber = props.itemManager.getFullBoxLocation(shipmentId)

                                    return <Option
                                        key={shipmentId}

                                    >{slotNumber}</Option>
                                })}
                            </Select>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '90%',
                                marginLeft: '5%',
                                marginTop: '2%',
                                marginBottom: '2%'
                            }}
                        >
                            <label>{bold('To Box:')}</label>
                            <Select
                                style={{
                                    width: '50%'
                                }}
                                placeholder="Please select"
                                value={toBoxSlot}
                                onChange={(value: any) => {
                                    setToShipmentId(value)
                                    setToBoxSlot(props.itemManager.getFullBoxLocation(value))
                                }}
                            >
                                {props.itemManager.getShipmentIdList().map((shipmentId: number) => {
                                    let slotNumber = props.itemManager.getFullBoxLocation(shipmentId)

                                    return <Option
                                        key={shipmentId}

                                    >{slotNumber}</Option>
                                })}
                            </Select>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '90%',
                            marginLeft: '5%',
                            marginTop: '2%',
                            marginBottom: '2%'
                        }}
                    >
                        <label>{bold('Item UPC:')}</label>
                        <Input
                            placeholder="Please Scan Item UPC!"
                            ref={scanInput}
                            autoFocus={props.focusToScanInput}
                            value={props.scannedItemUpc}
                            onChange={(e) => {
                                props.setScannedItemUpc(e.target.value)
                            }}
                            style={{
                                width: '50%'
                            }}
                        >
                        </Input>


                    </div>

                </div>}
        </div>

    )
}

export default MoveItemInput
