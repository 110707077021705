import React, {useEffect, useState} from "react";
import {ItemManager} from "../../Packing/ItemManager";
import {roundToFixed2} from "../../API/Utils";
import {bold, boldBlue, boldGreen, boldPurple, boldRed} from "../../Utils/HtmlUtils";


export type LivePriceProgressBarProps = {
    itemManager: ItemManager,
    shipmentId: number,
    numItemsScanned: number,
    boxName: string,
    shipMethodName: string
}

const LivePriceProgressBar: React.SFC<LivePriceProgressBarProps> = (props) => {

    const [liveShippingCost, setLiveShippingCost] = useState(0);

    useEffect(() => {
        let newLiveRate = props.itemManager.getRateForShipmentId(props.shipmentId);
        setLiveShippingCost(newLiveRate);

    }, [props.numItemsScanned, props.shipMethodName, props.boxName]);

    let originalRate = +props.itemManager.getOriginalShippingCostForShipmentId(props.shipmentId);
    let packerSavings = originalRate - liveShippingCost;

    let originalRateString = roundToFixed2(originalRate);
    let packerSavingsString = roundToFixed2(Math.abs(packerSavings));

    const commonReportStyle = {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid black",
        marginBottom: "5px",
        color: "black"
    }

    return <div>

        <div
            style={{
                textAlign: 'center',
            }}
        >
            <div style={commonReportStyle}>
                <span>{bold('Expected Full Box Rate:')} </span>
                <span>{boldRed('$')}{boldRed(originalRateString)} </span>
            </div>

            <div style={{
                ...commonReportStyle,
                borderBottom: "3px solid black"

            }}>

                <span>{bold('Current Live Rate:')} </span>
                <span>{boldBlue('- $')}{boldBlue(roundToFixed2(liveShippingCost))}</span>

            </div>


            {packerSavings >= 0 ?
                <div style={{
                    ...commonReportStyle,
                    borderBottom: "0px solid black"
                }}>
                    <span>{bold('Extra Savings:')} </span>
                    <span>{boldGreen(' = $')}{boldGreen(packerSavingsString)}</span>
                </div>

                :

                <div style={{
                    ...commonReportStyle,
                    borderBottom: "0px solid black"
                }}>
                    <span>{bold('Missed Savings:')} </span>
                    <span>{boldPurple('= -$')}{boldPurple(packerSavingsString)}</span>
                </div>
            }
        </div>

    </div>
}

export default LivePriceProgressBar
