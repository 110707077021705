import {getApiEnv} from "../Utils/ApiEnv";
import {noAuthEmail} from "../Hooks/auth/auth-utils";
import {cleanCategoryMap} from "../Utils/CleanCategoryMap.js";

export const backendCall = async (url: string, params: any, transform: (response: any) => any = response => response) => {
    let fullURL = `${getApiEnv().httpEndpoint}/${url}?`;

    Object.keys(params).forEach((key, index) => {
        fullURL = `${fullURL}&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    });

    // console.log('FULL URL = ', fullURL);

    try {
        const response = await (await fetch(fullURL)).json();
        // console.log(response)
        const result = transform(response)
        // console.log(result)
        const error = null

        return {result, error}

    } catch (e) {
        const error = e
        console.error('Something happened when fetching URL = ', fullURL)
        console.log(e)
        // alert(`Something went wrong when fetching data from the server, ERROR = ${e}, URL = ${fullURL}`)
        const result = null
        return {result, error}
    }
}


export const backendPostCall = async (url: string, body: any) => {
    let fullURL = `${getApiEnv().httpEndpoint}/${url}`;

    try {
        const response = await (await fetch(
            fullURL,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }
        )).json();
        // console.log(result)
        const error = null

        return {response, error}

    } catch (e) {
        const error = e
        console.error('Something happened when fetching URL = ', fullURL)
        console.log(e)
        // alert(`Something went wrong when fetching data from the server, ERROR = ${e}, URL = ${fullURL}`)
        const result = null
        return {result, error}
    }
}

export const todayInYYYYMMDDMinusDays = (numDays: number) => {
    const date = new Date();
    date.setDate(date.getDate() - numDays);
    return date.toISOString().slice(0, 10);
}

export const shortDateStringToDateObject = (shortDate: string): Date => {
    let date = new Date(shortDate);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);

    return date
}

export const shortDateStringToDateObjectWithoutShift = (shortDate: string): Date => {
    let date = new Date(shortDate);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate());

    return date
}


export const localDateNowHourAndMinute = (): string => {
    let date = new Date();

    let amPmString = date.getHours() >= 12 ? 'PM' : 'AM';

    let extraHourZero = date.getHours() < 10 ? '0' : '';
    let extraMinuteZero = date.getMinutes() < 10 ? '0' : '';

    return `${extraHourZero}${date.getHours()}:${extraMinuteZero}${date.getMinutes()} ${amPmString}`
}

export const utcToLocal = (utcDate: string): Date | any => {
    if (utcDate) {
        let localDate = new Date(utcDate.substring(0, 19))

        // check if hours and minutes are 00:00:00
        if (localDate.getHours() === 0 && localDate.getMinutes() === 0) {
            return localDate;
        }

        let offsetHours = localDate.getTimezoneOffset() / 60
        localDate.setHours(localDate.getHours() - offsetHours)
        return localDate;
    } else {
        return {}

    }

}

export const utcStringToLocalString = (utcDate: string): string => {
    let date = utcToLocal(utcDate);
    if (date) {
        return dateToLocalString(date);
    } else {
        return ''
    }

}

export const dateToLocalString = (date: Date): string => {
    return `${date.toLocaleString()} (EST)`

}

export const dateStringToLocalString = (date: string): string => {
    return dateToLocalString(new Date(date));


}

export const utcDateStringToLocalDateString = (utcDate: string): string => {
    return new Date(utcDate).toLocaleDateString();

}

export const utcDateStringToLocalDateStringPlus1Day = (utcDate: string): string => {
    let date = new Date(utcDate);
    date.setDate(date.getDate() + 1);
    return date.toLocaleDateString();
}

export const authSuccessful = (authUser: any, authPending: boolean): boolean => {
    if (authPending) return false;
    else {
        if (authUser.email === noAuthEmail) {
            return false
        } else {
            return true
        }

    }


}

export const todayInYYYYMMDD = () => {
    return todayInYYYYMMDDMinusDays(0);
}

export const roundToFixed2 = (x: number | undefined): string => {
    if (!x) return '0.00'

    return roundToFixed(x, 2)
}

export const roundToFixed4 = (x: number): string => {
    return roundToFixed(x, 4)
}

export const roundToFixed = (x: number, num_decimals: number): string => {
    return (Math.round(x * (10 ** num_decimals)) / (10 ** num_decimals)).toFixed(num_decimals);

}


export const getRedAndGreenColorValues = (value: string) => {
    let n = Math.round(+value)
    if (n > 50) n = 50
    if (n < -50) n = -50

    let g_color = Math.round((255 * (50 + n)) / 150)
    let r_color = Math.round((255 * (50 - n)) / 150)

    return [r_color, g_color]
}

export const columnTitle = (columnTitle: string, columnTooltip: string) => {
    return columnTitle
}


export const numberToUSD = (num: number): string => {
    if (num) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(num);
    } else {
        return ''
    }
}

export const formattedPackerName = (packerProfile: any): string => {
    let stationIndex = packerProfile?.packing_station_index;
    let fullName = packerProfile?.full_name;

    let nameSplit = fullName ? fullName.split(' ') : ['', ''];

    let firstNameInitial = nameSplit[0][0];
    let lastName = nameSplit[nameSplit.length - 1];

    return `${firstNameInitial}. ${lastName} @ St. ${stationIndex}`;

}


export const getColorForCategory = (category: string): string => {
    let color = 'black';

    if (category === 'Vape Juice' || category === 'Energy Drink') {
        color = 'green'
    }

    if (category === 'Coils' || category === 'Hairspray') {
        color = 'gray'
    }

    if (category === 'Hardware Battery') {
        color = 'orange'
    }

    if (category === 'Hardware Non-Battery') {
        color = 'brown'
    }

    return color

}

export const getColorForTag = (tag: string): string => {
    let color = 'blue';

    if (tag === 'SH' || tag === 'HAZMAT') {
        color = 'orange'
    }

    if (tag === 'UN3481' || tag === 'UN3480') {
        color = 'red'
    }

    if (tag === '420') {
        color = 'green'
    }

    return color

}


export const getCleanTag = (tag: string): string => {
    return tag
}

export const getCleanCategory = (category: string): string => {
    if (!category) return 'Other'

    if (category === '') return 'Other'

    if (['Vape Juice', 'Hardware Battery', 'Hardware Non-Battery', 'Coils'].includes(category)) {
        return category
    }

    if (Object.keys(cleanCategoryMap).includes(category)) {
        return cleanCategoryMap[category]
    }

    if (['Hairspray', 'Energy Drink'].includes(category)) {
        return category
    }


    return category
}

export const DEFAULT_FILL_RATE = 70


export const DEFAULT_ALL_SHIP_METHODS = [
    'USPS Priority Mail',
    'USPS Flat Rate',
    'FedEx Ground'
]

export const DEFAULT_SELECTED_SHIP_METHODS = [
    'USPS Priority Mail',
    'USPS Flat Rate'
]


export const DEFAULT_ALL_BOX_TYPES: Array<string> = [
    '18F',
    '12x6x6',
    '1/2 cube',
    '10x10x8',
    'Bong box',
    '12x12x9',
    '12Cube',
    '40F',
    'Large Flat Rate',
    'Medium Flat Rate',
    'Small Flat Rate',
    '18x12x12',
    'BFB',
    'Master',
    '16Cube',
    'MCX-8'
]

export const DEFAULT_ALL_BOX_TYPES_CUBIC: Array<string> = [
    '18F',
    '12x6x6',
    '1/2 cube',
    '10x10x8',
    'Bong box'
]

export const DEFAULT_SELECTED_BOX_TYPES: Array<string> = [
    // 'Large Flat Rate',
    // 'Medium Flat Rate',
    '18F',
    '12x6x6',
    '1/2 cube',
    '10x10x8',
    '12Cube',
    '12x12x9',
    '40F',
    '18x12x12'
]


export const SHIP_METHOD_TO_BOX_MAPPING = {
    'USPS Priority Mail': DEFAULT_ALL_BOX_TYPES,
    'USPS Priority Mail Cubic': DEFAULT_ALL_BOX_TYPES_CUBIC,
    'USPS Large Flat Rate Box': ['Large Flat Rate'],
    'USPS Medium Flat Rate Box': ['Medium Flat Rate'],
    'USPS Small Flat Rate Box': ['Small Flat Rate'],
    'FedEx Ground': [
        '12x6x6',
        'Bong box',
        '10x10x8',
        '1/2 cube',
        '12x12x9',
        '12Cube',
        '40F',
        'BFB',
        'Master',
        '16Cube',
        '18x12x12',
        'MCX-8'
    ],
} as any



export const range = (n: number) => Array.from({length: n}, (value, key) => key)
