import {Item} from "./Item";
import {roundToFixed, roundToFixed2} from "../API/Utils";

export class Box {
    protected readonly shipmentId: number;
    protected itemTypes: Set<string> = new Set<string>();
    protected itemIdsToItemsMap: Map<string, Item> = new Map<string, Item>(); // id -> item
    protected items: Map<string, Set<string>> = new Map<string, Set<string>>(); // itemType -> {id_1, id_2, ...}
    protected shipmentBody: any;

    constructor(itemTypes: Set<string>, itemIdsToItemsMap: Map<string, Item>, shipmentBody: any) {
        this.itemIdsToItemsMap = itemIdsToItemsMap;
        this.shipmentId = +shipmentBody.shipment_id;

        this.shipmentBody = shipmentBody;
        this.shipmentBody['box_slot_id'] = +shipmentBody.shipment_id + 1


        this.itemTypes = itemTypes;
        this.initializeItems();

    }

    public updateField(fieldName: any, fieldValue: any) {
        this.shipmentBody[fieldName] = fieldValue;
    }

    public initializeItems() {
        this.itemTypes.forEach((itemType) => {
            this.items.set(itemType, new Set<string>());
        })
    }

    public addItem(item: Item) {
        if (this.itemTypes.has(item.getType())) {
            this.items.get(item.getType())?.add(item.getId());
        }
    }

    public removeItem(item: Item) {
        if (this.itemTypes.has(item.getType())) {
            this.items.get(item.getType())?.delete(item.getId());
        }
    }

    public getItemSetForType(itemType: string): Set<string> {
        return this.items.get(itemType) as Set<string>;
    }

    getShipmentId(): number {
        return this.shipmentId;
    }

    public getAggregatedItems(sorter: any = null): any[] {
        let result: any[] = [];

        this.items.forEach((itemSet, itemType) => {
            if (itemSet.size > 0) {
                result.push([
                    itemType,
                    itemSet.size
                ])
            }
        })


        if(sorter) {
            result.sort(sorter);
        } else {
            // sort by item type prefix ascending and then by count descending
            result.sort((a, b) => {
                let a_type: string = a[0]
                let a_count: number = a[1];

                let b_type: string = b[0]
                let b_count: number = b[1];

                if (a_type === b_type) {
                    return b_count - a_count;
                } else {
                    return a_type.localeCompare(b_type);
                }
            })
        }

        return result;
    }

    public getCurrentItemCount(itemType: string): number {
        return this.items.get(itemType)?.size as number;
    }

    public getTotalCurrentItemCount(): number {
        let result: number = 0;
        this.items.forEach((itemSet, itemType) => {
            result += itemSet.size;
        })
        return result;
    }

    public getShipmentBody(): any {
        return this.shipmentBody;
    }

    public getCurrentItemIdSet(): Set<string> {
        let result: Set<string> = new Set<string>();
        this.items.forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                result.add(itemId);
            })
        })
        return result;
    }


}

export class VirtualBox extends Box {
    private shippingBox: ShippingBox;
    private originalItems: Map<string, Set<string>> = new Map<string, Set<string>>();

    public constructor(
        itemTypes: Set<string>,
        itemIdsToItemsMap: Map<string, Item>,
        shippingBox: ShippingBox,
        shipmentBody: any
    ) {
        super(itemTypes, itemIdsToItemsMap, shipmentBody);
        this.shippingBox = shippingBox;
        this.initializeOriginalItems();
    }

    public initializeOriginalItems() {
        this.itemTypes.forEach((itemType) => {
            this.originalItems.set(itemType, new Set<string>());
        })
    }

    public getNumberOfScannedItemsForType(itemType: string): number {
        let result: number = 0;
        this.shippingBox.getItems().get(itemType)?.forEach((itemId) => {
            let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
            if (item.isScanned()) {
                result += 1;
            }
        })
        return result;
    }

    public getOriginalAggregatedItems(): any[] {
        let result: any[] = [];

        // Initialize with empty sets for each item type
        let finalItems = new Map<string, Set<string>>();
        this.itemTypes.forEach((itemType) => {
            finalItems.set(itemType, new Set<string>());
        })

        // Add items from originalItems
        this.originalItems.forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                finalItems.get(itemType)?.add(itemId);
            })
        })

        // Add items from items that were moved to Shipping Box from different Virtual Box
        this.getShippingBox().getItems().forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
                if(item.isScanned()) {
                    finalItems.get(itemType)?.add(itemId);
                }
            })
        })

        finalItems.forEach((itemSet, itemType) => {
            if (itemSet.size > 0) {
                result.push([
                    itemType,
                    itemSet.size
                ])
            }
        })

        return result;
    }

    public getOriginalItemCount(itemType: string): number {
        return this.originalItems.get(itemType)?.size as number;
    }

    public getTotalOriginalItemCount(): number {
        let result: number = 0;
        this.originalItems.forEach((itemSet, itemType) => {
            result += itemSet.size;
        })
        return result;
    }

    public getShippingBox(): ShippingBox {
        return this.shippingBox;
    }

    public addItemToOriginal(item: Item) {
        if (this.itemTypes.has(item.getType())) {
            this.originalItems.get(item.getType())?.add(item.getId());
        }

    }

    public getOriginalItemIdSet(): Set<string> {
        let result: Set<string> = new Set<string>();
        this.originalItems.forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                result.add(itemId);
            })
        })
        return result;

    }

    public getNumberOfScannedItems(): number {
        let result: number = 0;
        this.getShippingBox().getItems().forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
                if (item.isScanned()) {
                    result += 1;
                }
            })
        })
        return result;
    }

    public getWeightOfScannedItems(): number {
        let result: number = 0;
        this.shippingBox.getItems().forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
                if (item.isScanned()) {
                    result += item.getWeightLbs();
                }
            })
        })

        result = +roundToFixed2(result)

        return result;

    }

    public getWeightOfOriginalItems(): number {
        let result: number = 0;
        this.originalItems.forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
                result += item.getWeightLbs();
            })
        })

        result = +roundToFixed2(result)

        return result;
    }

    public getVolumeOfOriginalItems(): number {
        let result: number = 0;
        this.originalItems.forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
                result += item.getVolumeCubicFeet();
            })
        })

        result = +roundToFixed(result, 4)

        return result;
    }

    public getVolumeOfScannedItems(): number {
        let result: number = 0;
        this.shippingBox.getItems().forEach((itemSet, itemType) => {
            itemSet.forEach((itemId) => {
                let item: Item = this.itemIdsToItemsMap.get(itemId) as Item;
                if (item.isScanned()) {
                    result += item.getVolumeCubicFeet();
                }
            })
        })

        result = +roundToFixed(result, 4)


        return result;
    }

    public getWeightOfScannedItemsFormatted(): string {
        // return weight formatted in LBS and OZ
        let weightLbs: number = this.getWeightOfScannedItems();
        let weightOz: number = weightLbs * 16;
        let lbs: number = Math.floor(weightLbs);
        let oz: number = Math.floor(weightOz % 16);

        return `${lbs} lbs ${oz} oz`;

    }

    getField(fieldName: string): any {
        return this.shipmentBody[fieldName];
    }

    public getNumberOfUnscannedItems(): number {
        let result: number = 0;
        this.items.forEach((itemSet, itemType) => {
            result += itemSet.size;
        })
        return result;
    }

    public getNumberOfUnscannedItemsForType(itemType: string): number {
        return this.items.get(itemType)?.size as number;
    }
}

export class ShippingBox extends Box {
    private readonly boxName: string;
    private readonly boxWeight: number;
    private readonly shipMethod: string;

    public constructor(
        itemTypes: Set<string>,
        itemIdsToItemsMap: Map<string, Item>,
        shipmentBody: any,
        boxName: string,
        boxWeight: number,
        shipMethod: string
    ) {
        super(
            itemTypes,
            itemIdsToItemsMap,
            shipmentBody
        );

        this.boxName = boxName;
        this.boxWeight = boxWeight;
        this.shipMethod = shipMethod;
    }

    public getBoxName(): string {
        return this.boxName;
    }

    public getShipMethod(): string {
        return this.shipMethod;
    }

    public getBoxWeight(): number {
        return this.boxWeight;
    }

    public getItems(): Map<string, Set<string>> {
        return this.items;
    }
}