import {ItemOperation} from "./ItemOperation";
import {ItemOperationResult} from "./ItemOperationResult";
import {Item} from "../Item";
import {ItemOperationStatus} from "./ItemOperationStatus";

export class ScanItemOperation extends ItemOperation {

    private readonly itemUpc: string;


    constructor(
        itemManager: any,
        packingStationId: string,
        itemUpc: string,
    ) {
        super(itemManager, packingStationId);
        this.itemUpc = itemUpc.trim();
    }

    public execute(resolve: (r: ItemOperationResult) => void): void {
        let itemOperationResult: ItemOperationResult;
        let itemType = this.validateScan(this.itemUpc, resolve);
        if (itemType === null) {
            return;
        }


        itemType = itemType as string;
        let item = this.getItems().find((item) => item.isAvailableForScan(itemType as string));

        if (item) {
            let optimalDestinationShipmentId = item.getOptimalShipmentId()
            let optimalDestinationBoxSlotId = this.itemManager.getBoxSlotIdForShipmentId(optimalDestinationShipmentId)

            item.moveToOptimalDestination();

            let remainingQuantity = item.getCurrentCountAtOrigin();
            let originalQuantity = item.getOriginalCountAtOrigin();
            let scannedQuantity = originalQuantity - remainingQuantity;

            itemOperationResult = new ItemOperationResult(
                item,
                ItemOperationStatus.SUCCESS,
                remainingQuantity,
                originalQuantity,
                scannedQuantity,
                'SUCCESS: SCANNED ITEM',
                [
                    `SCANNED ITEM: ${itemType}`,
                    `UPC: ${this.itemUpc}`,
                    `QUANTITY: ${scannedQuantity} of ${originalQuantity}`,
                    `INTO: ${this.itemManager.getFullBoxLocation(optimalDestinationShipmentId)}`,
                    `USER: ${this.itemManager.getAuthService().getUsername()}`

                ],
                '',
                []
            )


            this.getPackingStationService().scanItemSuccess(
                optimalDestinationBoxSlotId - 1,
                originalQuantity,
                scannedQuantity,
                itemType,
                this.itemUpc,
                r1 => {
                    this.actionLoggingService.logScanItemAction(
                        itemType as string,
                        optimalDestinationShipmentId,
                        r2 => resolve(itemOperationResult)
                    )
                })

        }


            // This means that all items have been moved... which means we have overs canned
        // Let's find a random item of the type to get the counts and return the error
        else {
            item = this.getItems().find((item) => item.getType() === itemType) as Item;
            let remainingQuantity = item.getCurrentCountAtOrigin();
            let originalQuantity = item.getOriginalCountAtOrigin();
            let scannedQuantity = item.getCurrentCountAtDestination();

            let optimalDestinationShipmentId = item.getOptimalShipmentId()
            let optimalDestinationBoxSlotId = this.itemManager.getBoxSlotIdForShipmentId(optimalDestinationShipmentId)

            itemOperationResult = new ItemOperationResult(
                item,
                ItemOperationStatus.OVER_SCANNED,
                remainingQuantity,
                originalQuantity,
                scannedQuantity,
                '',
                [],
                'ERROR: OVER SCANNED',
                [
                    `ITEM: ${itemType}`,
                    `UPC: ${this.itemUpc}`,
                    `QUANTITY: ${scannedQuantity} of ${originalQuantity} already scanned`,
                    `REMAINING: ${remainingQuantity} of ${originalQuantity} remaining`,
                    `INTO: ${this.itemManager.getFullBoxLocation(optimalDestinationShipmentId)}`,
                ]
            )

            this.getPackingStationService().overScannedItem(
                optimalDestinationBoxSlotId - 1,
                originalQuantity,
                scannedQuantity,
                itemType,
                this.itemUpc,
                r1 => {
                    this.actionLoggingService.logOverScanAction(
                        itemType as string,
                        optimalDestinationShipmentId,
                        r2 => resolve(itemOperationResult)
                    )
                }
            )

        }
    }


}