import LogRocket from "logrocket";
import {noAuthEmail} from "../Hooks/auth/auth-utils";

export class AuthService {

    private authUser: any;
    private readonly userName: string;
    public readonly packingStationIndex: number;
    private readonly photoUrl: string;
    private readonly fullName: string;


    constructor(
        authUser: any
    ) {
        this.authUser = authUser;
        this.userName = authUser.packer_username;
        this.packingStationIndex = authUser.packing_station_index;
        this.photoUrl = authUser.photo_url;
        this.fullName = authUser.full_name;

        LogRocket.identify(this.userName, {
            name: this.fullName,
            email: this.authUser.email,
            station: this.packingStationIndex
        });

    }

    isAuthenticated(): boolean {
        return !(this.authUser.email === noAuthEmail)
            && (this.authUser.email !== '')
    }

    getUsername(): string {
        return this.userName;
    }

    getPackingStationId() {
        return `STATION_${this.packingStationIndex}`;
    }

    getPackingStationIndex(): number {
        return this.packingStationIndex;
    }

    getStationIndexString(): string {
        return this.getPackingStationId().split('_')[1];
    }

    getFullLocationDescriptionForShipmentId(shipmentId: any): Array<string> {
        let stationIndex = this.getStationIndexString();
        return ['METAL RACK', `ST-${stationIndex}-BO-${+shipmentId + 1 <= 9 ? '0' : ''}${+shipmentId + 1}`];
    }

    getSlotNumberForShipmentId(shipmentId: any): string {
        return this.getFullLocationDescriptionForShipmentId(shipmentId)[1];
    }


    getName(): string {
        return this.fullName;

    }

    getPictureUrl(): string {
        return this.photoUrl;
    }

    getFirstName(): string {
        return this.fullName.split(' ')[0];
    }

}