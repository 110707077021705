import {ItemOperation} from "./ItemOperation";
import {ItemOperationResult} from "./ItemOperationResult";
import {Item} from "../Item";
import {ItemOperationStatus} from "./ItemOperationStatus";

export class ResetScanForItemInShipmentOperation extends ItemOperation {

    private readonly shipmentId: number;
    private readonly itemType: string;
    private readonly boxSlotId: any;

    constructor(
        itemManager: any,
        packingStationId: string,
        shipmentId: any,
        boxSlotId: any,
        itemType: string
    ) {
        super(itemManager, packingStationId);
        this.itemType = itemType;
        this.shipmentId = +shipmentId;
        this.boxSlotId = +boxSlotId;
    }

    public execute(resolve: (r: ItemOperationResult) => void): void {
        let virtualBox = this.getVirtualBox(this.shipmentId);
        let shippingBox = virtualBox.getShippingBox();

        let [locationDescription, locationNumber] = this.itemManager.getAuthService().getFullLocationDescriptionForShipmentId(this.shipmentId)
        let item = this.itemManager.getItemByTypeFromBox(shippingBox, this.itemType);

        if (!item) {
            let itemOperationResult = new ItemOperationResult(
                Item.emptyItem(),
                ItemOperationStatus.EMPTY,
                0,
                0,
                0,
                '',
                [],
                'CANNOT UN-SCAN',
                [
                    `ITEM: '${this.itemType}' `,
                    `PROBLEM: Is Not Scanned into BOX ${this.boxSlotId}`,
                    `SOLUTION 1: Please scan the item first`,
                    `SOLUTION 1: Are you trying to un-scan the wrong item?`,
                ]
            )

            this.getPackingStationService().scanItemFailure(
                this.itemType,
                itemOperationResult.shortErrorMessage,
                serverResponse => {
                    resolve(itemOperationResult);
                })
        } else {
            item.moveToOrigin();

            let originalCount = virtualBox.getOriginalItemCount(this.itemType);
            let scannedCount = shippingBox.getCurrentItemCount(this.itemType);

            let itemOperationResult = new ItemOperationResult(
                item,
                ItemOperationStatus.SUCCESS,
                0,
                originalCount,
                scannedCount,
                'UN-SCANNED ITEM',
                [
                    `UN-SCANNED: 1 of ${this.itemType}`,
                    `UPC: ${this.getUpcForItem(this.itemType)}`,
                    `QUANTITY: ${scannedCount} of ${originalCount} items scanned now`,
                    `IN: BOX ${this.boxSlotId}`,
                ],
                '',
                []
            )

            this.getPackingStationService().unscanItemSuccess(
                this.boxSlotId - 1,
                originalCount,
                scannedCount,
                this.itemType,
                this.getUpcForItem(this.itemType) as string,
                r1 => {
                    this.actionLoggingService.logResetScanForItemInShipmentAction(
                        this.itemType,
                        this.shipmentId,
                        r2 => resolve(itemOperationResult)
                    )
                }
            )
        }


    }


}