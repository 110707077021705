import React, {useEffect, useRef, useState} from "react";
import Markdown from 'markdown-to-jsx'
import './ChatComponent.css';

import {Button, Input} from "antd";
import {backendPostCall, localDateNowHourAndMinute} from "../../API/Utils";

import useAuthUser from "../../Hooks/auth/useAuthUser";
import {withRouter} from "react-router-dom";
import {AuthService} from "../../Service/AuthService";

const AIChatContainer = () => {
    const [loading, setLoading] = useState(false);
    const {authUser, authPending} = useAuthUser();

    const [allMessages, setAllMessages] = useState([] as any[]);
    const [newUserMessage, setNewUserMessage] = useState('');

    const authServiceRef = useRef(new AuthService(authUser));
    const inputRef = useRef(null);


    const userChatBubbleStyles = {
        backgroundColor: '#1890ff',
        color: 'white',
        textAlign: 'right'
    }

    const botChatBubbleStyles = {
        backgroundColor: 'white',
        color: 'black',
        textAlign: 'left'
    }

    const getChatBubbleStyles = (sender: string): any => {
        if (sender === 'Perseuss') {
            return botChatBubbleStyles;
        } else {
            return userChatBubbleStyles;
        }
    }

    const scrollToElement = (ref: any, delayMs: number = 100) => {
        setTimeout(() => {
            if (ref.current) {
                // @ts-ignore
                ref.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }, delayMs)
    }


    useEffect(() => {
        authServiceRef.current = new AuthService(authUser);

        if (authServiceRef.current.isAuthenticated()) {
            console.log('Auth successful');
        }
    }, [authUser, authPending])

    // TODO: the app is disabled for now
    const buttonDisabled = () => {
        // return true
        return loading || newUserMessage.trim() === '';
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !buttonDisabled()) {
            sendMessage()
        }
    }

    const sendMessage = () => {
        if (newUserMessage.trim() === '') return;

        setLoading(true);
        let userName = authServiceRef.current.getUsername();
        let firstName = authServiceRef.current.getFirstName()

        const userMessageObject = {
            sender: firstName,
            content: newUserMessage,
            message_time: localDateNowHourAndMinute()
        };

        setAllMessages([...allMessages, userMessageObject]);
        setNewUserMessage('');

        backendPostCall(
            '/ai-chat',
            {
                sender: userName,
                question: newUserMessage,
            }
        ).then((r: any) => {
                try {
                    console.log('Response = ', r);
                    const botMessage = {
                        sender: 'Perseuss',
                        content: r.response.response_body.answer,
                        message_time: localDateNowHourAndMinute()
                    };
                    setAllMessages((prev) => [...prev, botMessage]);
                } catch (e: any) {
                    const errorMessage = {sender: 'bot', content: 'Error: Could not fetch response.'};
                    setAllMessages((prev) => [...prev, errorMessage]);
                } finally {
                    setLoading(false);
                    scrollToElement(inputRef, 100);
                }
            }
        )
    };


    return (
        <div
            style={{
                marginLeft: '10%',
                width: '80%'
            }}
            onKeyPress={handleKeyPress}
        >
            <div
                style={{
                    marginTop: '2%',
                    textAlign: 'center'
                }}
            >
                <h1>Perseuss AI</h1>
                <h3>Ask me a question about Packing or Shipping 😉</h3>

            </div>


            <div className="chat-container">
                <div className="chat-window">

                    {allMessages.map((msg: any, index: number) => (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                            }}
                        >

                            {
                                msg.sender === 'Perseuss' ?
                                    <div
                                        style={{
                                            margin: '10px',
                                            padding: '10px',

                                        }}
                                    >
                                        <div>
                                            {msg.sender}
                                        </div>

                                        <img
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%'
                                            }}
                                            src={"https://i.imgur.com/on9qBnT.png"}
                                            alt="AI"

                                        />
                                        <div>
                                            {msg.message_time}
                                        </div>

                                    </div> : null

                            }

                            <div
                                style={{
                                    margin: '10px',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    flex: 1,
                                    ...getChatBubbleStyles(msg.sender)
                                }}
                            >
                                <Markdown>
                                    {msg.content}
                                </Markdown>
                            </div>

                            {
                                msg.sender !== 'Perseuss' ?
                                    <div
                                        style={{
                                            margin: '10px',
                                            padding: '10px',

                                        }}
                                    >
                                        <div>
                                            {msg.sender}
                                        </div>

                                        <img
                                            style={{
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '50%'
                                            }}
                                            src={authServiceRef.current.getPictureUrl()}
                                            alt="user"

                                        />
                                        <div>
                                            {msg.message_time}
                                        </div>

                                    </div> : null

                            }
                        </div>


                    ))}
                </div>
                <div
                    className="chat-input"
                    ref={inputRef}
                >
                    <Input
                        style={{
                            height: '50px',
                        }}
                        type="text"
                        ref={inputRef}
                        value={newUserMessage}
                        onChange={(e) => setNewUserMessage(e.target.value)}
                        placeholder="Example: How much does it cost to ship 20 pound 40F to CA?"
                    />
                    <Button
                        style={{
                            height: '50px',
                            borderRadius: '8px',
                        }}
                        onClick={sendMessage}
                        type={'primary'}
                        loading={loading}
                        disabled={buttonDisabled()}
                    >
                        COMING SOON!
                    </Button>
                </div>
            </div>

            <div
                style={{
                    height: '200px'
                }}
            >

            </div>
        </div>

    )

}

export default withRouter(AIChatContainer);
