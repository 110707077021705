import {v4 as uuidv4} from 'uuid';
import {ShippingBox, VirtualBox} from "./Box";
import {roundToFixed2} from "../API/Utils";

export class Item {

    public static fromRaw(itemBodyRaw: any): Item {
        let helper = Item.emptyItem();

        return new Item(
            itemBodyRaw.item_id,
            helper.getOrigin(),
            itemBodyRaw.width_inches,
            itemBodyRaw.height_inches,
            itemBodyRaw.length_inches,
            itemBodyRaw.weight_lbs,
            itemBodyRaw.volume_cubic_feet,
            itemBodyRaw.price_usd,
            itemBodyRaw.category,
            itemBodyRaw.brand_name,
            itemBodyRaw.variant_name,
            itemBodyRaw.image_thumbnail,
            itemBodyRaw.description_tags,
            itemBodyRaw.product_name
        );
    }


    // Physical features of Item
    private readonly widthInches: number;
    private readonly heightInches: number;
    private readonly lengthInches: number;
    private readonly weightLbs: number;
    private readonly volumeCubicFeet: number;
    private readonly priceUsd: number;
    private readonly category: string;
    private readonly brandName: string;
    private readonly variantName: string;
    private readonly productName: string;
    private readonly imageThumbnail: string;
    private readonly descriptionTags: Array<string>;


    // Internal features of Item
    private readonly itemType: string;
    private readonly itemId: string;
    private readonly origin: VirtualBox;
    private readonly optimalDestination: ShippingBox;

    private actualDestination: ShippingBox | null = null;
    private scanned: boolean;

    private static _generateId() {
        return uuidv4().substring(0, 8);
    }

    public static emptyItem(): Item {
        return new Item(
            '',
            new VirtualBox(new Set<string>(), new Map<string, Item>(), new ShippingBox(new Set<string>(), new Map<string, Item>(), {}, '', 0, ''), {}),
            0,
            0,
            0,
            0,
            0
        );
    }

    constructor(
        itemType: string,
        origin: VirtualBox,
        widthInches: number,
        heightInches: number,
        lengthInches: number,
        weightLbs: number,
        volumeCubicFeet: number,
        priceUsd: number = 0,
        category: string = '',
        brandName: string = '',
        variantName: string = '',
        imageThumbnail: string = '',
        descriptionTags: Array<string> = [],
        productName: string = ''
    ) {
        this.itemType = itemType;
        this.widthInches = widthInches;
        this.heightInches = heightInches;
        this.lengthInches = lengthInches;
        this.weightLbs = weightLbs;
        this.volumeCubicFeet = volumeCubicFeet;
        this.priceUsd = priceUsd;
        this.category = category;
        this.brandName = brandName;
        this.variantName = variantName;
        this.imageThumbnail = imageThumbnail;
        this.descriptionTags = descriptionTags;
        this.productName = productName;

        this.itemId = Item._generateId();
        this.scanned = false;

        this.optimalDestination = origin.getShippingBox();

        this.origin = origin;
        this.origin.addItem(this);
    }

    public getId(): string {
        return this.itemId;
    }

    public getType(): string {
        return this.itemType;
    }

    public moveToOptimalDestination(): void {
        this.actualDestination = this.optimalDestination;
        this.origin.removeItem(this);
        this.actualDestination.addItem(this);
        this.scanned = true;
    }

    public getOptimalShipmentId(): number {
        return this.optimalDestination.getShipmentId();
    }

    public getActualShipmentId(): number {
        if (this.actualDestination) {
            return this.actualDestination.getShipmentId();
        } else {
            alert(`${this.itemType} has no actual destination yet... it was not scanned...`);
            return 0;
        }
    }

    public getOptimalBoxName(): string {
        return this.optimalDestination.getBoxName();
    }

    public getOriginShipmentId(): number {
        return this.origin.getShipmentId();
    }

    public getCurrentCountAtOrigin(): number {
        return this.origin.getCurrentItemCount(this.itemType);
    }

    public getCurrentCountAtDestination(): number {
        if (this.actualDestination) {
            return this.actualDestination.getCurrentItemCount(this.itemType);
        } else {
            alert(`${this.itemType} has no actual destination yet... it was not scanned...`);
            return 0;
        }
    }

    public isAvailableForScan(itemType: string): boolean {
        return this.itemType === itemType && !this.scanned;

    }

    public isAvailableForMove(itemType: string, fromBox: VirtualBox, toBox: VirtualBox): boolean {
        if (this.itemType !== itemType) {
            return false;
        }

        if (this.scanned) {
            if (this.actualDestination?.getShipmentId() === toBox.getShipmentId()) {
                return false;
            } else return this.actualDestination?.getShipmentId() === fromBox.getShipmentId();
        } else {
            return this.origin.getShipmentId() === fromBox.getShipmentId();
        }


    }

    public getCurrentCountAtOptimalDestination(): number {
        return this.optimalDestination.getCurrentItemCount(this.itemType);
    }

    public getOriginalCountAtOrigin(): number {
        return this.origin.getOriginalItemCount(this.itemType);
    }

    public moveToDestination(destination: ShippingBox) {
        if (this.actualDestination) {
            this.actualDestination.removeItem(this);
        }

        this.actualDestination = destination;
        this.origin.removeItem(this);
        this.actualDestination.addItem(this);
        this.scanned = true;
    }

    public moveToOrigin() {
        if (this.actualDestination) {
            this.actualDestination.removeItem(this);
            this.actualDestination = null;
        }
        this.origin.addItem(this);
        this.scanned = false;
    }

    getOptimalDestination() {
        return this.optimalDestination;
    }

    public isScanned(): boolean {
        return this.scanned;
    }

    public getVolumeCubicFeet(): number {
        return this.volumeCubicFeet;
    }

    public getWeightLbs(): number {
        return this.weightLbs;
    }

    public getFormattedWeight(): string {
        return this.getFormattedWeightForNumberOfItems(1);
    }

    public getNonFormattedWeightForNumItems(numItems: number): number {
        let result: number = this.weightLbs * numItems;
        result = +roundToFixed2(result);

        return result;
    }

    public getFormattedWeightForNumberOfItems(numItems: number): string {
        // return formatted to lbs and oz
        let weightLbs: number = this.weightLbs * numItems;
        let weightOz: number = weightLbs * 16;
        let lbs: number = Math.floor(weightLbs);
        let oz: number = +roundToFixed2(weightOz % 16);

        if (lbs === 0)
            return `${oz} oz`;
        else {
            if (oz === 0)
                return `${lbs} lbs`;
            else
                return `${lbs} lbs ${oz} oz`;
        }
    }

    public getLengthInches(): number {
        return this.lengthInches;
    }

    public getHeightInches(): number {
        return this.heightInches;
    }

    public getWidthInches(): number {
        return this.widthInches;
    }

    public getItemType(): string {
        return this.itemType;
    }

    public getBrandName(): string {
        return this.brandName;
    }

    public getCategory(): string {
        return this.category;
    }

    public getCategoryAsList(): Array<string> {
        return [this.category];
    }

    public getDescriptionTags(): Array<string> {
        // Sort the tags alphabetically
        return this.descriptionTags.sort();
    }

    public getImageThumbnail(): string {
        return this.imageThumbnail;
    }

    public getPriceUsd(): number {
        return this.priceUsd;
    }

    public getVariantName(): string {
        return this.variantName;
    }

    public getOrigin(): VirtualBox {
        return this.origin;
    }

    public getActualDestination(): ShippingBox | null {
        return this.actualDestination;
    }

    public getProductName(): string {
        return this.productName;
    }


}