import {Tag} from 'antd';
import * as React from 'react';
import {getCleanCategory, getCleanTag, getColorForCategory, getColorForTag} from "../API/Utils";

export const bold = (text: any) => {
    return <b>{text}</b>
}

export const boldLink = (text: any, link: any) => {
    return <b>
        <a
            href={link}
            target={"_blank"}
            rel={"noreferrer noopener"}
        >{text}</a>
    </b>
}
export const boldRed = (text: any) => {
    return <b style={{
        color: 'red'
    }}>{text}</b>
}

export const boldGreen = (text: any) => {
    return <b style={{
        color: 'green'
    }}>{text}</b>
}

export const boldBlue = (text: any) => {
    return <b style={{
        color: 'blue'
    }}>{text}</b>
}

export const boldOrange = (text: any) => {
    return <b style={{
        color: 'orange'
    }}>{text}</b>
}

export const boldOrangeLink = (text: any, link: any) => {
    return <b style={{
        color: 'orange'
    }}>
        <a
            href={link}
            target={"_blank"}
        >{text}</a>
    </b>

}

export const boldPurple = (text: any) => {
    return <b style={{
        color: 'purple'
    }}>{text}</b>
}

export const getDescriptionTag = (descriptionTag: string, count: number = 0, index: number = 0) => {
    let cleanTag = getCleanTag(descriptionTag)
    let color = getColorForTag(cleanTag)

    return <Tag
        color={color}
        key={index}

        style={{
            margin: '2px'
        }}

    > {count > 0 ? `${count} x ` : ''}{cleanTag} </Tag>
}


export const getCategoryTag = (category: string, count: number = 0, index: number = 0) => {
    let cleanCategory = getCleanCategory(category)
    let color = getColorForCategory(cleanCategory)
    return <Tag
        color={color}
        key={index}

        style={{
            margin: '2px'
        }}

    >{count > 0 ? `${count} x ` : ''}{cleanCategory} </Tag>
}

export const getAlgorithmTag = (algorithm: string, index: number = 0) => {
    let color = 'blue'

    if (algorithm.includes('JUICE LOGIC')) {
        color = 'purple'

    } else if (algorithm === 'BASELINE') {
        color = 'red'

    } else if (algorithm.startsWith('V2(W=15')) {
        color = '#6568ff'

    } else if (algorithm.startsWith('V2(W=20')) {
        color = '#ff3269'

            } else if (algorithm.startsWith('V2(W=25')) {
        color = '#ff6232'

    } else if (algorithm.startsWith('V2')) {
        color = '#ff65b5'

    } else if (algorithm.startsWith('MILP')) {
        color = 'green'

    } else if (algorithm.startsWith('GREEDY')) {
        color = 'orange'

    }

    return <Tag key={index} color={color}>{algorithm}</Tag>
}


export const getStatusTag = (status: string, index: number = 0) => {
    let color = 'blue'

    if (status.includes('SHIPPED COMPLETELY')) {
        color = 'green'
    } else if (status.includes('PACKING IN PROGRESS')) {
        color = 'blue'
    } else if (status.includes('SHIPPING IN PROGRESS')) {
        color = 'orange'
    }

    return <Tag key={index} color={color}>{status}</Tag>

}