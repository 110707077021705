import {ItemOperation} from "./ItemOperation";
import {ItemOperationResult} from "./ItemOperationResult";
import {Item} from "../Item";
import {ItemOperationStatus} from "./ItemOperationStatus";

export class ResetScanForAllItemsOperation extends ItemOperation {

    private readonly shipmentId: number;
    private readonly boxSlotId: any;

    constructor(
        itemManager: any,
        packingStationId: string,
        shipmentId: any,
        boxSlotId: any
    ) {
        super(itemManager, packingStationId);
        this.boxSlotId = boxSlotId;
        this.shipmentId = +shipmentId;
    }

    public execute(resolve: (r: ItemOperationResult) => void): void {
        let virtualBox = this.getVirtualBox(this.shipmentId);
        let notScannedItems = virtualBox.getCurrentItemIdSet()
        let allItems = virtualBox.getOriginalItemIdSet();

        let originalCount = allItems.size;
        let scannedCount = originalCount - notScannedItems.size;

        allItems.forEach((itemId) => {
            let item = this.getItemForId(itemId) as Item;
            item.moveToOrigin();
        });


        let itemOperationResult = new ItemOperationResult(
            Item.emptyItem(),
            ItemOperationStatus.SUCCESS,
            0,
            originalCount,
            scannedCount,
            'SUCCESS: UN-SCANNED ALL ITEMS',
            [
                `UN-SCANNED: ${scannedCount} items`,
                `QUANTITY: 0 of ${originalCount} items scanned now`,
                `IN: ${this.itemManager.getFullBoxLocation(+this.shipmentId)}`,
            ],
            '',
            []
        )

        this.getPackingStationService().resetScanForAllItems(
            this.boxSlotId - 1,
            originalCount,
            r1 => {
                this.actionLoggingService.logResetScanAllAction(
                    this.shipmentId,
                    r2 => {
                        resolve(itemOperationResult)
                    })
            }
        )

    }


}