import {Button} from "antd";
import React from "react";

import {bold, boldGreen, boldLink, boldRed} from "../../Utils/HtmlUtils";

type BlogContainerProps = {
    blogWidthPercent: number;
    contentWidthPercent: number;
    videoHeightPx: number;
    fontSizePx: number;
}


const BlogContainer = (props: BlogContainerProps) => {


    return (
        <div
            style={{
                marginTop: "2%",
                width: `${props.blogWidthPercent}%`,
                marginLeft: `${(100 - props.blogWidthPercent) / 2}%`,
                textAlign: "left",
                fontSize: `${props.fontSizePx}px`
            }}
            id={"blog-container"}

        >
            {/*Add list item stylesheet*/}
            <style>
                {`#blog-container  ul li {
                            margin-bottom: 15px;
                        }
                  #blog-container ol li {
                            margin-bottom: 15px;
                        }      
                `}
            </style>
            <div
                style={{
                    textAlign: "center"
                }}
            >
                <h1>B2B Distributor Case Study</h1>
                <p
                    style={{
                        fontSize: "1.75em",
                        color: "black"
                    }}
                >Customer: <a
                    href={"https://medusadistribution.com"}
                    target={"_blank"}
                >
                    Medusa Distribution
                </a></p>
            </div>

            <h3>{bold('Key Takeaways')}</h3>
            <ol>
                <li>
                    {bold('Cost Savings: ')} Perseuss Smart Packing Algorithm can save {bold('10-20%')} on shipping cost
                    for B2B distributors
                </li>
                <li>
                    {bold('Enhanced Efficiency: ')} Our user friendly interfaces make employees happier, more productive
                    and less prone to errors
                </li>
                <li>
                    {bold('Zero Risk: ')} Success is guaranteed. If we do not save you money, you do not pay us.
                </li>
            </ol>


            <h3>{bold('Video')}</h3>

            <div
                style={{
                    marginBottom: "3%",
                    marginLeft: `${(100 - props.contentWidthPercent) / 2}%`,
                }}
            >
                <iframe
                    src="https://www.youtube.com/embed/mKe6aUi3DbM?autoplay=1&mute=1"
                    allow={"autoplay"}
                    width={`${props.contentWidthPercent}%`}
                    height={`${props.videoHeightPx}px`}
                    title={'Medusa Case Study Video'}
                />

            </div>

            <div
                style={{
                    marginBottom: "100px",
                }}
            >


                <h3>{bold('Context')}</h3>

                <p>
                    {boldLink('Medusa', 'https://medusadistribution.com')} is a wholesaler based
                    in {bold('New Jersey (USA)')}.
                    Medusa maintains an inventory of over {bold('10,000 SKUs')}, which it sells to retailers (online and
                    bricks-and-mortar) who in turn sell to consumers.
                    A typical retailer order will require a Medusa employee to pack and ship
                    between {bold('100 and 300 order lines')} weighing in total between {bold('20 and 100 pounds')}.
                </p>

                <p>
                    To ship these large orders, the employee would have to figure out how many boxes to use, of what
                    size, and how to divide the SKUs among the several boxes.
                    Once the boxes are packed and sealed, for each box, the employee selects the carrier
                    – {bold('USPS')}, {bold('UPS')}, or {bold('FedEx')} – and the appropriate ship method.
                </p>

                <p>
                    {bold('Before Perseuss')}, the employee would do the easiest thing: select a small number of large
                    boxes and pack them with SKUs, using their best judgment about how to fit the items into each box (the
                    famous computer science “bin packing” problem).
                    Easy for the employee, but this “naïve” approach results in shipping costs that
                    are {boldRed('10-20% higher')} than necessary!
                </p>


                <p>
                    {bold('With Perseuss')}, the employee is given a simple fun application that tells her exactly what
                    boxes to use, what SKUs to place in each box, and automatically prints out the correct shipping
                    label for each box.
                    The Perseuss software considers both the physical dimensions and the weight of every SKU in the
                    order, and then computes a {bold('Packing and Shipping Plan (PaSP)')} to minimize the shipping
                    charges and speed up the work of filling the boxes.
                </p>


                <h3>{bold('Example Before: Packer Decides')}</h3>
                <h4>{boldRed('Shipping Cost = $288.32')}</h4>

                <p>
                    Imagine you are a packer that needs to pack and ship {bold('295 items')} that
                    weigh {bold('137 lbs')} and take up {bold('5.63 cubic feet of space')} from New York to
                    to Wisconsin. There are many ways to split the items into boxes and they can have vastly different
                    shipping costs. You could put it
                    into {bold('5 large boxes')}, {bold('12 medium boxes')}, {bold('25 small boxes')} or any combination
                    of
                    thereof.
                </p>

                <p>

                </p>

                <p>
                    For most packers, their strategy to pack an order like this would be to minimize the number of boxes
                    used.
                    In this case, this would mean {bold('5')} boxes weighing between {bold('25 and 30 lbs')} and
                    costing to ship {bold('between $55 and $65')} via USPS Priority Mail. The total shipment cost would
                    be {bold('$288.32')}
                </p>
                <p>
                    This is a very reasonable approach for a human. You use less carton and you can achieve a higher
                    fill rate for the individual boxes.
                    It feels efficient. You are saving the
                    environment! {bold('This has to be the correct way, right? NO!')}
                </p>
                <p>
                    Please see the cost breakdown below:
                </p>

                <h3>{bold('Example After: Perseuss Decides')}</h3>
                <h4>{boldGreen('Shipping Cost = $208.56 (27.6% savings)')}</h4>

                <p>
                    Counter-intuitively, the {bold('Perseuss Smart Packing Algorithm')} figured out that,
                    in this case, it is actually much cheaper to use more smaller boxes. However, this is where it gets
                    tricky.
                </p>

                <p>
                    Sometimes the most efficient way to pack an order is to use big boxes, sometimes the optimal
                    solution is to
                    use small boxes. It is not always the same and sometimes it is a combination. It primarily depends
                    on the following 4
                    variables:
                </p>

                <ol>
                    <li>
                        {bold('Weights of individual items:')} It tends to be cheaper to pack lighter items in big boxes
                        and heavier items in small boxes. But it all depends on the aggregate "density" of the order.
                    </li>
                    <li>
                        {bold('Dimensions of boxes and items:')} Certain box sizes have certain carrier discounts and
                        some have additional fees. Fitting right items in the right boxes is crucial.
                    </li>
                    <li>
                        {bold('Shipping distance:')} The further the distance, the more expensive mistakes get
                    </li>
                    <li>
                        {bold('Business Constraints:')} Maybe carrier A cannot ship to certain locations or carrier B
                        cannot carry items X, Y and Z.
                    </li>
                </ol>


                <p>
                    Going back to the original case, Perseuss recommended to use {bold('12 x 0.5 cube')} boxes with the
                    USPS
                    Priority Mail Cubic shipping
                    label. This way,
                    each box weighs around {bold('11 lbs')} and costs exactly {bold('$17.38')}. Hence,
                    the overall shipping cost is {bold('12 * $17.38 = $208.56')}, which is
                    almost {bold('$80 (27%)')} cheaper than
                    the {bold('human-intuitive')} packing method.
                </p>

                <p>
                    Please see the cost breakdown below:
                </p>


                <h3>{bold('Additional Benefits of Perseuss')}</h3>
                <ol>
                    <li>{bold('Easier to Catch Mistakes: ')} The UX of
                        our {(boldLink('Perseuss App', 'https://perseuss.xyz'))} is friendlier than of standard
                        Warehouse Management Systems (WMS).
                        We display more information in a clearer way, with images and visual animations. Because of
                        this, packers are able to catch mistakes more often and fix them immediately.
                    </li>
                    <li>{bold('Gamification of Work: ')} Our users say that packing with Perseuss is literally like
                        "playing a video game". They both enjoy work more and are measurably more productive.
                    </li>
                    <li>{bold('Integrations: ')} We do not need to build a new system just for you. If you are using a
                        standard WMS, we plug into their APIs and you can start saving money almost immediately.
                    </li>

                </ol>


                <h3>{bold("Let's Chat!")}</h3>
                <p>If you liked what you see, we would like to discuss how we could help you save {bold('10-20%')} on
                    shipping
                    cost and make your employees happier and more productive.</p>

                <div
                    style={{
                        textAlign: "center"


                    }}
                >
                    <Button
                        href={"https://calendly.com/tom-3muv/perseuss-demo"}
                        target={"_blank"}
                        type={"primary"}
                        style={{
                            fontSize: "20px"
                        }}
                    >Schedule a Demo!</Button>
                </div>


            </div>


            <div> .</div>

        </div>
    )

}

export default BlogContainer;
