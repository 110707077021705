import React, {useEffect, useState} from "react";
import {Button, Input, Table, Select, Slider, Icon, Checkbox} from "antd";
import {
    authSuccessful, backendCall,
    DEFAULT_ALL_BOX_TYPES, DEFAULT_ALL_SHIP_METHODS,
    DEFAULT_FILL_RATE,
    DEFAULT_SELECTED_BOX_TYPES, DEFAULT_SELECTED_SHIP_METHODS
} from "../../API/Utils";
import useAuthUser from "../../Hooks/auth/useAuthUser";
import {getSolutionTableColumns} from "../../Components/TableColumns/SolutionTableColumns";
import {getApiEnv} from "../../Utils/ApiEnv";
import ToteInfoView from "../../Components/Shared/ToteInfoView";
import PackerStatsView from "../../Components/Shared/PackerStatsView";
import {bold} from "../../Utils/HtmlUtils";
import {AuthService} from "../../Service/AuthService";

const {Option} = Select;

interface OptimizeToteDynamicProps {
    parentRequestId: string
}

const OptimizeToteDynamicContainer = (props: OptimizeToteDynamicProps) => {
    const [toteId, setToteId] = useState<string>("")
    const [realtime, setRealtime] = useState<boolean>(true)
    const [childRequestId, setChildRequestId] = useState<string>("")

    const [searchLoading, setSearchLoading] = useState<boolean>(false)
    const [toteDataLoading, setToteDataLoading] = useState<boolean>(false)
    const [allowItemSplitting, setAllowItemSplitting] = useState<boolean>(false);

    const [packingDisabled, setPackingDisabled] = useState<boolean>(true);

    const {authUser, authPending} = useAuthUser();
    const [fillRate, setFillRate] = useState<number>(DEFAULT_FILL_RATE);

    const maxNumAllowedBoxes = 10;
    const allBoxTypes = DEFAULT_ALL_BOX_TYPES;
    const [selectedBoxTypes, setSelectedBoxTypes] = useState<Array<string>>(DEFAULT_SELECTED_BOX_TYPES);


    const allShipMethods = DEFAULT_ALL_SHIP_METHODS;
    const [selectedShipMethods, setSelectedShipMethods] = useState<Array<string>>(DEFAULT_SELECTED_SHIP_METHODS);


    let solutionsTemp: any = [];
    let numSolutions = 0;
    let bestCost = 99999999999;


    const [solutions, setSolutions] = useState<any[]>([])

    const updateSolutions = (newSolution: any) => {
        solutionsTemp = [...solutionsTemp, newSolution]

        // sort solutions by shipping cost
        solutionsTemp.sort(
            (a: any, b: any) => a.tote_data.cost - b.tote_data.cost
        )

        setSolutions(solutionsTemp.map((solution: any, index: number) => {
            return {
                ...solution,
                key: index
            }
        }))
    }

    useEffect(() => {
        setToteDataLoading(true);
        if (authSuccessful(authUser, authPending)) {
            if (props.parentRequestId) {
                setToteId('XXX');
                setRealtime(false);
            }
            setToteDataLoading(false);

        }

    }, [authUser, authPending, props.parentRequestId]);

    const generateUPCMap = () => {

        // UPC will load max 30 seconds
        setTimeout(() => {
            setPackingDisabled(false);
        }, 30000);

        if (realtime) {
            // Wait 15 seconds for some solutions to come before enabling packing
            setTimeout(() => {

                backendCall('/get-upc-mapping-v2', {
                    tote_id: toteId
                }).then((response: any) => {
                    setPackingDisabled(false);
                })

            }, 15000);


        } else {
            setPackingDisabled(false);
        }

    }

    const handleBoxTypeSelection = (selectedOptions: any) => {
        setSelectedBoxTypes(selectedOptions)
    }

    const handleShipMethodSelection = (selectedOptions: any) => {
        setSelectedShipMethods(selectedOptions)
    }

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !buttonDisabled()) {
            handleClick()
        }
    }

    const buttonDisabled = (): boolean => {
        return searchLoading || !toteId || selectedBoxTypes.length === 0
    }


    const handleClick = () => {
        setSearchLoading(true);
        generateUPCMap();


        let wsAPIUrl = `${getApiEnv().websocketEndpoint}/solution-search`;
        const socket = new WebSocket(wsAPIUrl);
        setChildRequestId("");

        // Set all variables to default
        solutionsTemp = [];
        numSolutions = 0;
        bestCost = 99999999999;
        setSolutions([]);


        socket.onopen = () => {
            socket.send(JSON.stringify({
                search_params: {
                    box_fill_rate: fillRate,
                    allowed_box_types: selectedBoxTypes,
                    allowed_old_ship_vias: selectedShipMethods,
                    realtime: realtime,
                    tote_id: toteId,
                    request_id: props.parentRequestId,
                    random_seed: 39,
                    max_search_time: 60,
                    allow_item_splitting: allowItemSplitting,
                    packer: new AuthService(authUser).getUsername()
                }
            }))

        }

        socket.addEventListener('message', ev => {
            try {
                const eventData = JSON.parse(ev.data);
                let optimisationDone = eventData.optimisation_done;

                if (optimisationDone) {

                    if (eventData.optimisation_status === 'success') {

                        setSearchLoading(false);
                        setToteDataLoading(false);
                        console.log('Search completed...')
                        socket.close()
                    } else {
                        setSearchLoading(false);
                        setToteDataLoading(false);
                        alert('[ERROR] -> ' + eventData.message)
                        socket.close()
                    }

                    return
                } else if (eventData.new_solution) {
                    setToteDataLoading(false);

                    // Only update if new solution is better than the last
                    // or if there are no solutions
                    // console.log(eventData.new_solution)

                    if (numSolutions === 0 || eventData.new_solution.tote_data.cost < bestCost) {
                        numSolutions = numSolutions + 1
                        bestCost = eventData.new_solution.tote_data.cost

                        let result = eventData.new_solution
                        setToteId(result['tote_data']['tote_id'])
                        setChildRequestId(result['request_id'])

                    }
                    updateSolutions(eventData.new_solution)


                    return
                } else if (eventData.connection_status === 'success') {
                    setSearchLoading(true);
                } else {
                    alert('Unknown Error ' + JSON.stringify(eventData))
                    setSearchLoading(false);
                }
            } catch (e) {
                setSearchLoading(false);
                console.log(e)
                alert(e)
            }
        })
    }


    return (
        <div
            onKeyPress={handleKeyPress}
            tabIndex={0}
            style={{
                width: "90%",
                marginLeft: "5%",
                marginTop: "0px",
                textAlign: "center",
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                < div
                    style={{
                        width: "25%",
                        border: "1px solid black",
                        borderRadius: "10px",
                    }}
                >
                    <ToteInfoView
                        solutionData={solutions[0]}
                        loading={toteDataLoading}
                        authService={new AuthService(authUser)}
                    />

                </div>

                <div
                    style={{
                        width: "45%",
                        border: "1px solid black",
                        borderRadius: "10px",
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        style={{
                            borderBottom: '1px solid #1890ff',
                            height: "57px",
                            paddingTop: "1%",
                        }}
                    >
                        {
                            realtime ? <h1>Optimize Tote (Realtime)</h1> : <h1>Replay Tote (Offline)</h1>
                        }
                    </div>

                    <div
                        style={{
                            width: '75%',
                            marginLeft: '12.5%',
                        }}
                    >
                        {realtime ? <div>
                                <h3>Tote ID</h3>
                                {childRequestId ? <p>{bold('Child Request: ')}{childRequestId}</p> : null}
                                <Input
                                    placeholder="Tote ID"
                                    value={toteId}
                                    onChange={(e) => {
                                        setToteId(e.target.value)
                                    }}
                                    style={{
                                        'width': '100%'
                                    }}
                                >
                                </Input>
                            </div> :
                            <div>
                                <h3>Request IDs</h3>
                                <p>{bold('Parent Request: ')}{props.parentRequestId}</p>
                                {childRequestId ? <p>{bold('Child Request: ')}{childRequestId}</p> : null}

                            </div>
                        }
                    </div>

                    <div
                        style={{
                            width: '75%',
                            marginLeft: '12.5%',
                        }}
                    >
                        <h3>Ship Methods</h3>
                        <Select
                            mode="multiple"
                            style={{width: '100%'}}
                            placeholder="Please select"
                            defaultValue={selectedShipMethods}
                            onChange={handleShipMethodSelection}
                        >
                            {allShipMethods.map((boxType: string) => (
                                <Option
                                    key={boxType}

                                >{boxType}</Option>
                            ))}
                        </Select>
                    </div>


                    <div
                        style={{
                            width: '75%',
                            marginLeft: '12.5%',
                        }}
                    >
                        <h3>Box Types</h3>
                        <Select
                            mode="multiple"
                            style={{width: '100%'}}
                            placeholder="Please select"
                            defaultValue={selectedBoxTypes}
                            onChange={handleBoxTypeSelection}
                        >
                            {allBoxTypes.map((boxType: string) => (
                                <Option
                                    key={boxType}
                                    disabled={
                                        selectedBoxTypes.length >= maxNumAllowedBoxes
                                            ? selectedBoxTypes.includes(boxType)
                                                ? false
                                                : true
                                            : false
                                    }

                                >{boxType}</Option>
                            ))}
                        </Select>
                    </div>

                    <div
                        style={{
                            width: '75%',
                            marginLeft: '12.5%'
                        }}
                    >
                        <h3>Box Fill Rate = {fillRate}%</h3>
                        <Slider
                            value={fillRate}
                            onChange={(e: any) => setFillRate(e)}
                            disabled={false}
                        />
                    </div>

                    <div
                        style={{
                            marginBottom: '5%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',

                            }}
                        >
                            <label>Allow Item Splitting</label>
                            <Checkbox
                                onChange={(e) => setAllowItemSplitting(e.target.checked)}
                                checked={allowItemSplitting}
                            ></Checkbox>
                        </div>
                        <div>
                            <Button
                                type={"primary"}
                                onClick={handleClick}
                                disabled={buttonDisabled()}
                                loading={searchLoading}
                            >
                                Find Shipments <Icon type={'search'}></Icon>
                            </Button>
                        </div>
                        <div></div>
                    </div>
                </div>


                <div
                    style={{
                        width: "25%"
                    }}
                >
                    <PackerStatsView
                        authUser={authUser}
                    />
                </div>
            </div>

            <div
                style={{
                    marginTop: "10px",

                }}
            >
                <Table
                    dataSource={solutions}
                    columns={getSolutionTableColumns(packingDisabled)}
                    loading={searchLoading && solutions.length === 0}
                    pagination={{pageSize: 25}}

                />
            </div>
        </div>


    )

}

export default OptimizeToteDynamicContainer;
